export function condenseHeader(){
    let header = document.getElementsByClassName( 'hdr' );
    header[0].classList.add('small');
}

export function uncondenseHeader(){
    let header = document.getElementsByClassName( 'hdr' );
    header[0].classList.remove('small');
}

export function hideHeader(){
    let header = document.getElementsByClassName( 'hdr' );
    header[0].classList.add('-translate-y-full');
}

export function showHeader(){
    let header = document.getElementsByClassName( 'hdr' );
    header[0].classList.remove('-translate-y-full');
}

export function hideBackToTop(){
    let backTop = document.getElementsByClassName( 'back_to_top' );
    backTop[0].classList.add('translate-x-28');

}

export function showBackToTop(){
    let backTop = document.getElementsByClassName( 'back_to_top' );
    backTop[0].classList.remove('translate-x-28');
}

export function swapClasses(ele, class1, class2){
    if(ele){
        ele.classList.toggle( class1 );
        ele.classList.toggle( class2 );
    }
}

export function debounce(func, wait) {
    let timeout;
    return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
}