import '../css/app.pcss';
import { debounce } from '../js/modules/globals/tools.js';

// Import Fonts
import '../fonts/IntelOneMono-Regular.woff';
import '../fonts/IntelOneMono-Regular.woff2';

/* ------------------------------------------------
CORE:
Load any critical scripts first, including functionality
that exists on every page.
------------------------------------------------*/
import accessibility from './modules/globals/accessibility.js';
import animations from './modules/globals/animations.js';
import burgerMenu from './modules/globals/hamburger_menu.js';

console.log('[WEB-APP] Javascript has been loaded.');

// Lazy Load images
import LazyLoad from "vanilla-lazyload";
const lazyContent = new LazyLoad({});

// Cache DOM selectors
const gsapVideos = document.querySelectorAll(".gsap-video");
const bkgdVideos = document.querySelectorAll(".bkgd-video-player");
const allVideos = [...gsapVideos, ...bkgdVideos];
const ftr = document.querySelector("#ftr");

// Function to handle scroll event
function handleScroll() {
    const scroll = window.scrollY;
    const ftrRect = ftr.getBoundingClientRect();

    if (ftrRect.top <= window.innerHeight && ftrRect.bottom >= 0) {
        allVideos.forEach(video => video.classList.add('opacity-0'));
    } else {
        allVideos.forEach(video => video.classList.remove('opacity-0'));
    }
}

// Debounced scroll event handler
const debouncedHandleScroll = debounce(handleScroll, 100);

// Add scroll event listener
window.addEventListener("scroll", debouncedHandleScroll);


/* ==========================================================================
========================================================================== */

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}