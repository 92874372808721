import { condenseHeader, uncondenseHeader  } from './tools.js';

const bodyTag = document.getElementsByTagName('body');

if( bodyTag[0].classList.contains('home') ){
    /* --------------------------------------------------------------------
    Hide header bar on scroll.
    Reveal after period of inactivity.
    -------------------------------------------------------------------- */
    let hideHeaderTriggerPoint = 100;
    var isScrolling, isWaiting, lastScrollPos;

    window.addEventListener('scroll', function(){
        if(getYPosition() >= 25){
            condenseHeader();
        }
        else{
            if( !bodyTag[0].classList.contains('menu-open') ){
                uncondenseHeader();
            }
        }

        lastScrollPos = getYPosition();
    }, { passive: true });
}

function getYPosition(){
    var top  = window.pageYOffset || document.documentElement.scrollTop
    return top;
}


/* --------------------------------------------------------------------
Anchor links with the anchor class to the assigned target.
Requires .anchor class on a link and valud href #hash
-------------------------------------------------------------------- */
let anchors = document.getElementsByClassName('anchor');

for (var i = anchors.length - 1; i >= 0; i--) {

    anchors[i].addEventListener('click', function(e){
        e.preventDefault();

        let link = e.target;
        let anchorTarget = link.hash.replace('#','');

        if(anchorTarget){
            let header = document.getElementsByClassName( 'hdr' );
            let destination = document.getElementById( anchorTarget );
            let additionalPadding = 20;

            document.body.parentNode.scrollTo({
                top: destination.offsetTop - (header[0].offsetHeight + additionalPadding),
                left: 0,
                behavior: 'smooth'
            });
        }
        else{
            console.error('The link ['+link+'] has an anchor class but the href is not a valid hash.')
        }

    });
};