/* --------------------------------------------------------------------
Import accessibility helpers
--------------------------------------------------------------------*/
import { toggleAriaExpanded, addTabIndex, removeTabIndex  } from './accessibility.js';
import { swapClasses  } from './tools.js';

// Global
global.menuOpen = false;

/* --------------------------------------------------------------------
Handle Burger Menu
--------------------------------------------------------------------*/
const bodyTag = document.getElementsByTagName('body');
const openNavBtn = document.querySelectorAll('.burger_btn');
const navigation = document.querySelectorAll('.navigation');
const navigationChild = document.querySelectorAll('.navigation > div');
const header = document.querySelectorAll('.hdr');
const headerGrid = document.querySelectorAll('.hdr > .grid');

const mainLogo = document.querySelectorAll('.main-logo');
const miniLogo = document.querySelectorAll('.mini-logo');
const pageTitle = document.querySelectorAll('.page-title');

if (navigation && openNavBtn) {
    /* ------------------------------------------------------------
    Update Focus state
    ------------------------------------------------------------ */
    openNavBtn[0].addEventListener("click", function(){
        toogleNavMenu();
    });

    function toogleNavMenu(){
        var bars = openNavBtn[0].querySelectorAll('span');

        swapClasses(openNavBtn[0], 'group/closed', 'group/open');

        if(bars.length == 2){
            if(bars[0] && bars[1]){
                if ( !global.menuOpen ) {
                    bars[0].classList.add('animate-burgertop');
                    bars[1].classList.add('animate-burgerbottom');
                }
                else{
                    bars[0].classList.remove('animate-burgertop');
                    bars[1].classList.remove('animate-burgerbottom');

                    window.setTimeout(function(){

                        bars[0].classList.add('animate-burgertop_reverse');
                        bars[0].addEventListener("animationend", () => {
                            bars[0].classList.remove('animate-burgertop_reverse');
                        });

                        bars[1].classList.add('animate-burgerbottom_reverse');
                        bars[1].addEventListener("animationend", () => {
                            bars[1].classList.remove('animate-burgerbottom_reverse');
                        });
                    }, 1);
                }
            }
        }

        if(navigation[0].classList.contains('-translate-y-full') ){
            global.menuOpen = true;

            bodyTag[0].classList.add('menu-open');
            header[0].classList.add('small');

            // Aria Label Toggle
            openNavBtn[0].ariaLabel = "Close Navigation Menu";

            // Set Tab Indexes
            removeTabIndex('tabbablelink');
        }
        else{
            global.menuOpen = false;

            bodyTag[0].classList.remove('menu-open');
            // Aria Label Toggle
            openNavBtn[0].ariaLabel = "Open Navigation Menu";

            // Set Tab Indexes
            addTabIndex('tabbablelink');
        }

        /* Toogle Classes on globally */
        swapClasses( navigation[0], '-translate-y-full', 'translate-y-0');
        swapClasses( navigationChild[0], 'lg:border-t-0', 'lg:border-t');


        /* Toogle Classes on interior pages */
        if (pageTitle.length && mainLogo.length) {
            // swapClasses( header[0], 'bg-black', 'bg-transparent');
            // swapClasses( headerGrid[0], 'lg:pb-0', 'lg:pb-4');
            // swapClasses( mainLogo[0], 'hidden', 'block');
            // swapClasses( miniLogo[0], 'block', 'hidden');
            // swapClasses( pageTitle[0], 'block', 'hidden');
        }
    }

    /* ------------------------------------------------------------
    List for key board input
    • If ESC key pressed, close menu
    ------------------------------------------------------------ */
    document.addEventListener("keyup", (e) => {
        if(global.menuOpen){
            if (e.key === "Escape") {
                toogleNavMenu();
            }
        }
    });
}
else{
    console.warn('Navigation or Burger Element missing.');
}